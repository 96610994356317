<template>
  <div class="page-wrapper">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="首页轮播图" name="first">
        <Section :pageId="4" name="首页轮播图" />
      </el-tab-pane>
      <el-tab-pane label="产品专栏轮播图" name="second"
        ><Section :pageId="2" name="产品专栏轮播图"
      /></el-tab-pane>
      <el-tab-pane label="庭院专栏轮播图" name="third"
        ><Section :pageId="5" name="庭院专栏轮播图"
      /></el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import Section from "./components/Section";
export default {
  name: "CarouselManager",
  components: {
    Section,
  },
  data() {
    return {
      activeName: "first",
    };
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
  },
};
</script>

<style lang="scss" scoped>
.page-wrapper {
  box-sizing: border-box;
  padding: 10px;
  width: 100%;
  height: 100%;
  background-color: #fff;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
