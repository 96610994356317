<template>
  <div class="page-wrapper">
    <el-row :gutter="10">
      <el-col :sm="24" :md="24">
        <!-- 轮播图 -->
        <el-card class="box-card" shadow="never" v-loading="loading">
          <div slot="header" class="clearfix">
            <span>{{ name }} [拖动排序](从左到右排序) </span>
            <div style="float: right; padding: 3px 0">
              <el-button size="small" @click="onRefresh">刷新</el-button>
              <el-button type="primary" size="small" @click="onSave"
                >保存</el-button
              >
            </div>
          </div>
          <DraggbleSort syncSortKey v-model="sections" class="banner-list-wrap">
            <template v-slot:default="slotProps">
              <el-form
                :model="slotProps.item"
                label-position="right"
                label-width="100px"
                size="small"
              >
                <el-row :gutter="10">
                  <el-col :md="24" :lg="24">
                    <ProductVideoItem
                      v-if="slotProps.item.typeId == 2"
                      v-model="slotProps.item.videoUrl"
                      class="banner-item"
                      :size="20480"
                      @remove="
                        removeUrl(slotProps.index, slotProps.item.typeId)
                      "
                    />
                    <!-- https://cdn.viewgres.com/ecde5a896702271c0e2a531c27bb90d7 -->
                    <ProductImgUpload
                      v-else
                      class="banner-item"
                      tips="选择图片"
                      v-model="slotProps.item.imageUrl"
                      @remove="
                        removeUrl(slotProps.index, slotProps.item.typeId)
                      "
                    />

                    <!-- <el-form-item label="(点击拖动排序)上传"></el-form-item> -->
                  </el-col>
                  <el-col :md="24" :lg="24">
                    <el-form-item
                      v-if="slotProps.item.typeId == 2"
                      label="视频链接"
                      required
                    >
                      <el-input
                        v-model="slotProps.item.videoUrl"
                        clearable
                      ></el-input>
                    </el-form-item>
                    <LocaleFormTab>
                      <template #cn>
                        <el-row :gutter="10">
                          <el-col :sm="24" :md="24">
                            <el-form-item label="标题(中文)" required>
                              <el-input
                                v-model="slotProps.item.title.cn"
                                clearable
                              ></el-input>
                            </el-form-item>
                          </el-col>
                          <el-col :sm="24" :md="24">
                            <el-form-item label="副标题(中文)">
                              <el-input
                                v-model="slotProps.item.subTitle.cn"
                              ></el-input>
                            </el-form-item>
                          </el-col>
                        </el-row>

                        <el-form-item label="描述词(中文)">
                          <el-input
                            v-model="slotProps.item.description.cn"
                            type="textarea"
                            :rows="3"
                            clearable
                          ></el-input>
                        </el-form-item>
                      </template>
                      <template #en>
                        <el-row :gutter="10">
                          <el-col :sm="24" :md="24">
                            <el-form-item label="标题(英文)" required>
                              <el-input
                                v-model="slotProps.item.title.en"
                                clearable
                              ></el-input>
                            </el-form-item>
                          </el-col>
                          <el-col :sm="24" :md="24">
                            <el-form-item label="副标题(英文)">
                              <el-input
                                v-model="slotProps.item.subTitle.en"
                                clearable
                              ></el-input>
                            </el-form-item>
                          </el-col>
                        </el-row>

                        <el-form-item label="描述词(英文)">
                          <el-input
                            v-model="slotProps.item.description.en"
                            type="textarea"
                            :rows="3"
                            clearable
                          ></el-input>
                        </el-form-item>
                      </template>
                    </LocaleFormTab>
                    <el-col :sm="24" :md="24"
                      ><el-form-item label="链接到">
                        <el-input
                          v-model="slotProps.item.redirectUrl"
                        ></el-input> </el-form-item
                    ></el-col>
                    <el-col :sm="24" :md="24" style="text-align: right;">
                      <el-button
                        class="mt-r"
                        size="small"
                        :type="slotProps.item.hidden ? 'danger' : 'success'"
                        :icon="
                          slotProps.item.hidden
                            ? 'el-icon-warning-outline'
                            : 'el-icon-view'
                        "
                        @click="slotProps.item.hidden = !slotProps.item.hidden"
                        >{{
                          slotProps.item.hidden ? "已隐藏" : "已显示"
                        }}</el-button
                      >
                      <el-popover placement="top" width="160">
                        <p>确定删除吗？</p>
                        <div style="text-align: right; margin: 0">
                          <el-button
                            type="primary"
                            size="mini"
                            @click="onRemveSection(slotProps.index)"
                            >确定</el-button
                          >
                        </div>
                        <el-button slot="reference" type="danger" size="small"
                          >删除</el-button
                        >
                      </el-popover>
                    </el-col>
                  </el-col>
                </el-row>
              </el-form>
            </template>
          </DraggbleSort>
          <div class="add-madias-item">
            <el-button-group>
              <el-button
                type="primary"
                size="small"
                icon="el-icon-picture-outline"
                @click="addBanner(1)"
                >添加图片</el-button
              >
              <el-button
                type="primary"
                size="small"
                icon="el-icon-picture-outline"
                @click="addBanner(2)"
                >添加视频</el-button
              >
            </el-button-group>
          </div>
        </el-card>
      </el-col>
      <el-col :sm="24" :md="12"></el-col>
    </el-row>
  </div>
</template>

<script>
import {
  GetPagesById,
  GetPagesSectionsById,
  PutPages,
} from "@/views/pagesManager/api";
import DraggbleSort from "@/components/DraggbleSort";
import ProductImgUpload from "@/components/ProductImgUpload";
import ProductVideoItem from "@/components/ProductVideoItem";
import LocaleFormTab from "@/components/LocaleFormTab";
import { buildI18nKeyValue } from "@/utils/common";
export default {
  name: "HOME",
  props: {
    pageId: {
      type: Number,
      default: 2,
      required: true,
    },
    name: {
      type: String,
      default: "轮播图设置",
    },
  },
  components: {
    DraggbleSort,
    ProductImgUpload,
    ProductVideoItem,
    LocaleFormTab,
  },
  data() {
    return {
      loading: false,
      currentPageId: 2,
      sections: [],
      page: {},
    };
  },
  computed: {
    localeColumns() {
      return this.$store.getters.localeColumns;
    },
  },
  created() {
    this.currentPageId = this.pageId;
    this.page = this.buildPageForm();
    this.initSetting();
    // {
    //             "seq": 0,
    //             "hidden": true,
    //             "imageUrl": "",
    //             "typeId": null,
    //             "redirectUrl": "",
    //             "videoCoverUrl": "",
    //             "videoUrl": "",
    //             "title": "banner",
    //             "redirectWxMiniAppPath": "",
    //             "icon": "",
    //             "description": "首页轮播图",
    //             "subTitle": "",
    //             "videos": [],
    //             "images": [
    //                 {
    //                     "url": "https://www.viewglass.cn/_nuxt/banner.6069abaa.jpg",
    //                     "seq": 0,
    //                     "subTitle": "",
    //                     "hidden": false,
    //                     "title": "",
    //                     "description": "",
    //                     "icon": ""
    //                 }
    //             ],
    //             "content": ""
    //         },
    //          {
    //             "seq": 1,
    //             "hidden": true,
    //             "imageUrl": "https://www.viewglass.cn/_nuxt/section1.39bceb11.jpg",
    //             "typeId": null,
    //             "redirectUrl": "",
    //             "videoCoverUrl": "",
    //             "videoUrl": "",
    //             "title": "aboutus",
    //             "redirectWxMiniAppPath": "",
    //             "icon": "",
    //             "description": "首页关于我们",
    //             "subTitle": "",
    //             "videos": [],
    //             "images": [],
    //             "content": "Located in the scenic ceramic hub of Foshan, China, Viewglass has been a trusted name in the glass mosaic industry since 2005. Embracing the evolving trends and the importance of sustainable practices, Viewglass embarked on a transformation journey in 2014, pioneering the manufacturing of recycled glass mosaics in China."
    //         },
    //         {
    //             "seq": 2,
    //             "hidden": true,
    //             "imageUrl": "",
    //             "typeId": null,
    //             "redirectUrl": "",
    //             "videoCoverUrl": "",
    //             "videoUrl": "",
    //             "title": "services",
    //             "redirectWxMiniAppPath": "",
    //             "icon": "",
    //             "description": "首页提供的服务",
    //             "subTitle": "",
    //             "videos": [],
    //             "images": [
    //                 {
    //                     "url": "https://www.viewglass.cn/_nuxt/service1.01d7805a.png",
    //                     "seq": 0,
    //                     "subTitle": "",
    //                     "hidden": false,
    //                     "title": "R & D strength",
    //                     "description": "Professional appearance design, development can realize the whole process from idea to object",
    //                     "icon": ""
    //                 },
    //                  {
    //                     "url": "https://www.viewglass.cn/_nuxt/service2.d5a20b50.png",
    //                     "seq": 1,
    //                     "subTitle": "",
    //                     "hidden": false,
    //                     "title": "Product quality",
    //                     "description": "It has passed ISO9001,ISO14001, ISO45001,Greenleaf, CE certification and many other domestic and foreign certifications",
    //                     "icon": ""
    //                 },
    //                 {
    //                     "url": "https://www.viewglass.cn/_nuxt/service3.99a3e79b.png",
    //                     "seq": 2,
    //                     "subTitle": "",
    //                     "hidden": false,
    //                     "title": "Production ensure",
    //                     "description": "Professional production team, skilled operation, skilled technology, high efficiency and high quality production guarantee",
    //                     "icon": ""
    //                 },
    //                 {
    //                     "url": "https://www.viewglass.cn/_nuxt/service4.2b1b7586.png",
    //                     "seq": 3,
    //                     "subTitle": "",
    //                     "hidden": false,
    //                     "title": "OEM experience",
    //                     "description": "German, Arnerican, Australia,Japanese and Korean brands have many years of OEM experience and high cost performance",
    //                     "icon": ""
    //                 }
    //             ],
    //             "content": ""
    //         },
  },
  watch: {
    pageId: {
      handler(val) {
        this.currentPageId = val;
        this.initSetting();
      },
    },
  },

  methods: {
    buildPageForm() {
      return {
        name: "",
        typeId: null,
        title: {
          ...this.localeColumns,
        },
        parentId: null,
        description: {
          ...this.localeColumns,
        },
        subTitle: {
          ...this.localeColumns,
        },
        sections: [],
        hidden: false,
        icon: "",
        seq: 0,
      };
    },
    buildSectionItem() {
      return {
        seq: 0,
        hidden: false,
        imageUrl: "",
        typeId: null,
        redirectUrl: "",
        videoCoverUrl: "",
        videoUrl: "",
        title: { ...this.localeColumns },
        icon: "",
        description: {
          ...this.localeColumns,
        },
        subTitle: {
          ...this.localeColumns,
        },
        videos: [],
        images: [],
        content: "",
      };
    },
    initSetting() {
      this.loading = true;
      GetPagesById(this.currentPageId)
        .then((res) => {
          for (const key in res.data) {
            if (Object.hasOwnProperty.call(this.page, key)) {
              this.page[key] = res.data[key];
            }
          }
          this.page["title"] = buildI18nKeyValue(res.data.title);
          this.page["subTitle"] = buildI18nKeyValue(res.data.subTitle);
          this.page["description"] = buildI18nKeyValue(res.data.description);
          return GetPagesSectionsById(this.currentPageId);
        })
        .then((res) => {
          this.sections = [];
          res.data.forEach((item) => {
            this.sections.push({
              ...item,
              title: buildI18nKeyValue(item.title),
              subTitle: buildI18nKeyValue(item.subTitle),
              description: buildI18nKeyValue(item.description),
            });
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onSave() {
      let _form = {
        ...this.page,
        sections: [...this.sections],
      };
      PutPages([
        {
          id: this.currentPageId,
          ..._form,
        },
      ]).then(() => {
        this.$message.success("保存成功");
        this.initSetting();
      });
    },
    onRefresh() {
      this.initSetting();
    },
    // 元素图
    addBanner(sectionType) {
      // _sectionType可选值,1标识图片，2标识视频
      let _sectionType = sectionType ? sectionType : 1;
      let _section = this.buildSectionItem();
      _section.typeId = _sectionType;
      this.sections.push(_section);
    },
    onRemveSection(index) {
      this.sections.splice(index, 1);
    },
    removeUrl(index, typeId) {
      if (typeId == 2) {
        this.sections[index].videoUrl = "";
      }
      if (typeId == 1) {
        this.sections[index].imageUrl = "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.page-wrapper {
  box-sizing: border-box;
  padding: 10px;
  width: 100%;
  height: 100%;
  background-color: #fff;
  overflow-x: hidden;
  overflow-y: auto;
}
.box-card {
  margin: 10px 0;
}
.banner-list-wrap {
  width: 100%;
  ::v-deep {
    .transition-el {
      max-height: none;
      gap: 10px;
    }
    .draggable-item {
      margin: 5px;
      flex: 1 0 0;
      max-width: 400px;
      min-width: 300px;
      box-shadow: 0 0 12px #ececec;
      box-sizing: border-box;
      padding: 10px 20px;
    }
  }
}
::v-deep {
  .custom-draggable {
    max-width: 100%;
  }
}
.banner-item {
  width: 100%;
  min-height: 200px;
  max-height: 260px;
  aspect-ratio: 16/9;
  margin: 0 auto;
  overflow: hidden;
}
.about-us-image {
  width: 100%;
  max-width: 450px;
  height: 260px;
  ::v-deep {
    .img-upload-wrapper {
      height: auto;
    }
    .uploaded-img-preview {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.services-image {
  box-sizing: border-box;
  padding: 2px;
  width: 100px;
  height: 100px;
  margin: 0 auto;
  background-color: hsl(15, 88%, 55%);
  &:hover {
    background-color: hsl(15, 88%, 55%);
  }
}
.add-madias-item {
  text-align: center;
  padding-top: 10px;
}
</style>
